import { Inject, Injectable } from '@angular/core';

import { GoogleInitOptions, GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { take } from 'rxjs/operators';
import { INTERVAL_DELAY } from 'src/utils/constants';

@Injectable({ providedIn: 'root' })
export class CustomGoogleLoginProvider extends GoogleLoginProvider {
  constructor(@Inject(String) clientId: string, @Inject(Object) initOptions?: GoogleInitOptions) {
    super(clientId, initOptions);
  }

  async signIn(): Promise<any> {
    const accessToken = await this.getAccessToken();

    return Promise.resolve({ authToken: accessToken });
  }

  getAccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if ((this as any)._tokenClient.h?.closed) {
          window.clearInterval(interval);
          reject('window closed');
        }
      }, INTERVAL_DELAY);

      if (!(this as any)._tokenClient) {
        if ((this as any)._socialUser.value) {
          reject('No token client was instantiated, you should specify some scopes.');
        } else {
          reject('You should be logged-in first.');
        }
      } else {
        (this as any)._tokenClient.requestAccessToken({
          hint: (this as any)._socialUser.value?.email
        });
        (this as any)._receivedAccessToken.pipe(take(1)).subscribe(resolve);
      }
    });
  }
}
